import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css'; //v6.4.xxx
import LazyLoad from "vanilla-lazyload";
import { Luminous, LuminousGallery } from "luminous-lightbox";


const imagesLoaded = require('imagesloaded'); //プリロード

// [CSS]
import "../scss/app.scss";


const body = document.body;

let device_ratio = 1;
const moblie_hreshold = 560; //mobileとの分岐width

// 言語の判別
const current_lang = document.body.classList.contains('lang_ja')? 'ja' : 'en';


const get_easing = (p) => {
  return 1 - Math.pow(1 - p, 7);
}

// 指定したY座標へのスムーススクロール
// https://www.mitsue.co.jp/knowledge/blog/frontend/201801/18_1027.html
/**
 * 
 * @param {int} goalY 
 * @param {int} startY 
 */
const smoothscroll = (goalY, startY) => {
  if(!startY) startY = 0; //頭からスクロール
  let positionY = 0; // スクロールする位置
  let progress = 0; // 現在の進捗 0 ～ 100
  
  let move = function () {
    progress++;
    positionY = goalY * get_easing(progress / 100);
    window.scrollTo(0, positionY);
    if (positionY < goalY) { // 現在位置が目的位置より進んでいなければアニメーションを続行させる
      requestAnimationFrame(move);
    }
  };
  requestAnimationFrame(move);
};


(function () {
  let body = document.body;
  let lazyLoadInstance = new LazyLoad();
  if(window.devicePixelRatio) {
    device_ratio = window.devicePixelRatio;
  }

  initPreload();
  initHeader();
  initSwiper();

  const luminousOpts = {
    caption: (trigger) => {
      return trigger.querySelector("img").getAttribute("alt");
    }
  };

  if(document.getElementById('site-home')) {
    new LuminousGallery(document.querySelectorAll(".js-luminous"), {}, luminousOpts);
  }
  else if(document.getElementById('site-inspiration')) {
    new LuminousGallery(document.querySelectorAll(".js-luminous"), {}, luminousOpts);
  }
  else if(document.querySelector('.page-template-tmpl_page')) {
    new LuminousGallery(document.querySelectorAll(".js-luminous"), {}, luminousOpts);
  }
  else if(document.getElementById('site-access')) {
    initModuleTab();
  }
  else if(document.getElementById('site-contact')) {
    initModuleTab();
  }
  
  // リサイズ時にY各要素のY座標を取得し直す
  /*
  const resize = ()=>{
    let timeoutID = 0;
    let delay = 500;
    window.addEventListener("resize", ()=>{
      clearTimeout(timeoutID);
      timeoutID = setTimeout(()=>{
        ////// リサイズ処理 //////
        console.log("resize"); 
      }, delay);
    }, false);
  };
  */
  //resize();


  if(document.getElementById('site-home')) {
    const boxes = document.querySelectorAll(".c-section");
    const options = {
      root: null, // viewport
      rootMargin: "-50% 0px", // centerY
      threshold: 0
    };
    const observer = new IntersectionObserver(doWhenIntersect, options);
    boxes.forEach(box => {
      observer.observe(box);
    });

    function doWhenIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          activateIndex(entry.target);
        }
      });
    }
    function activateIndex(element) {
      const currentActiveIndex = document.querySelector(".l-menu__link.is_current");
      if (currentActiveIndex !== null) {
        currentActiveIndex.classList.remove("is_current");
      }
      const newActiveIndex = document.querySelector('.l-menu__link--'+element.id);
      newActiveIndex.classList.add("is_current");
    }
    // for mobile
    if(window.innerWidth <= 560) {

    }
  }
})();


/**
 * pre-loading : ローディングバー
 * refer: https://hirakublog.com/code/374/
 */
function initPreload() {
  // [Loading - Progress Bar]
  const progressBar = document.querySelector('.js-loader-progress-bar')
  if(!progressBar) return;
  
  const progressNumber = document.querySelector('.js-loader-progress-number')
  const container_element = body; //読み込み対象コンテナ
  const bgPreload_class = '.__bg-preload';
  
  const imgLoader = imagesLoaded(container_element, {background: bgPreload_class});
  const imgTotal = imgLoader.images.length; //画像の総数を取得
  
  let imgLoaded = 0;      //ロードした画像カウント
  let progressSpeed = 8;  //プログレスバーの進むスピード 大きいほど速くなる
  let progressCount = 0;  //ローディングの進捗状況 0〜100
  let progressResult = 0; //ローディングの進捗状況 0〜100
  let progressInit;

  if(!imgTotal) {
    progressBar.style.width = '100%';
    progressNumber.innerText = 100;
    setTimeout(function () {
      body.classList.add('is-loaded');
    }, 800);
  }
  else {
    progressInit = setInterval(updateProgress, 25);
    //画像を一つロードするたびにimgLoadedのカウントをアップ
    imgLoader.on('progress', function (instance, image) {
      console.log(image);
      console.log('* * *');
      imgLoaded++
    })
  }
  
  //読み込み状況アップデート関数
  function updateProgress() {
    //ローディング演出用の計算
    progressCount += (imgLoaded / imgTotal) * progressSpeed;
  
    if(progressCount >= 100 && imgTotal > imgLoaded) {
      //カウントは100以上だが画像の読み込みが終わってない
      progressResult = 99;
    } else if(progressCount > 99.9) {
      //カウントが100以上になった
      progressResult = 100;
    } else {
      //現在のカウント
      progressResult = progressCount;
    }
  
    //ローディング進捗状況をプログレスバーと数字で表示
    progressBar.style.width = progressResult + '%';
    progressNumber.innerText = Math.floor(progressResult);
    
    //ローディング完了後 0.8秒待ってページを表示
    if (progressResult >= 100 && imgTotal == imgLoaded) {
      clearInterval(progressInit);
      setTimeout(function () {
        document.querySelector('body').classList.add('is-loaded');
      }, 800);
    }
  }
}

function initSwiper() {
  // [各ページ (Hero Image有り)]
  if(document.getElementById('hero_image')) {
    let mvSwiper = new Swiper ('.swiper-container', {
      loop: true,
      effect: 'fade',
      speed: 1500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    })
  }
  

  // [HOME]
  if(document.getElementById('site-home')) {
    let mvSwiper = new Swiper ('.swiper-container', {
      loop: true,
      effect: 'fade',
      speed: 1500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    })
  }
}

function initHeader() {
  const header = document.getElementById("siteHeader");
  const header_menu = document.querySelector(".l-menu");
  const hH = header.clientHeight;
  let pos = 0;
  let lastPos = 0;
  const winH = window.innerHeight;
  const docH = document.documentElement.scrollHeight;
  const windBtm = docH - winH;
  const isHome = document.body.classList.contains('home');
  
  const onScroll = () => {
    /* if(pos > hH && pos > lastPos) {
      header.classList.add('__unpinned');
    }
    if(pos < hH || pos < lastPos || windBtm <= pos) {
      header.classList.remove('__unpinned');
    } */
    if(pos > 80) {
      // header_menu.classList.add('__up');
      header.classList.add('__up');
    }
    else {
      // header_menu.classList.remove('__up');
      header.classList.remove('__up');
    }
    lastPos = pos;
  };

  const onScroll_for_home = () => {
    if(pos < winH) {
      header.classList.add('__hide');
    }
    else {
      header.classList.remove('__hide');
      if((pos > hH && pos > lastPos)) {
        header.classList.add('__unpinned');
      }
      else if(pos < hH || pos < lastPos || windBtm <= pos) {
        header.classList.remove('__unpinned');
      }
    }
    lastPos = pos;
  };

  window.addEventListener("scroll", () => {
    pos = window.scrollY;
    onScroll();
  });



  
  // #ナビボタン
  const navi_btn = document.getElementById('button-gmenu');
  navi_btn.addEventListener('click', function(ev){
    ev.preventDefault();
    if(!body.classList.contains('is-menu-open')) {
      //body.classList.remove('hide-nav');
      body.classList.add('is-menu-open');
    }
    else {
      body.classList.remove('is-menu-open');
      //body.classList.add('hide-nav');
    }
  });

  //　トップページでアンカーリンク時にメニューを閉じる
  if(document.getElementById('site-home')) {
    const menu_links = document.querySelectorAll('.l-spmenu__link');
    const menu_linksAry = Array.prototype.slice.call(menu_links); //for IE11
    menu_linksAry.forEach(function(ml) {
      if(~ml.getAttribute('href').indexOf('#')) {
        ml.addEventListener('click', function(ev){
          body.classList.remove('is-menu-open');
        });
      }
    });
  }


  // ページトップへのスムーススクロール
  // https://www.imuza.com/entry/smoothscroll
  const scroll_totop_ = () => {
    let startY = window.pageYOffset;
    const scroll = () => {
        startY = window.pageYOffset;
        let y = startY - (startY - 0) / 20 - 1;
        window.scrollTo(0, y);
        if (y < 0) return;
        requestAnimationFrame(scroll);
    };
    requestAnimationFrame(scroll);
  };
}

function initModuleTab(target_tab = document.querySelector('.mdl_tab'), target_index = 1) {
  if(!target_tab) return false;

  let tabHead = target_tab.querySelector('.mdl_tab-head');
  let tabBody = target_tab.querySelector('.mdl_tab-body');
  let tabBody_items = tabBody.querySelectorAll('.mdl_tab-body__item');

  let tab_inputs =  tabHead.querySelectorAll('input');
  let _headIndex = 1;
  let _bodyIndex = 1;

  let coverimage = document.querySelector('.sct-saijiki .cover');
  
  //body側のindex付与
  tabBody_items.forEach(tabBody_item => {
    tabBody_item.setAttribute('data-index', _bodyIndex);
    tabBody_item.classList.add('mdl_tab-body__item--index'+_bodyIndex);
    if(target_index == _bodyIndex) {
      tabBody_item.classList.add('__show-tabitem');
    }
    _bodyIndex++
  });

  //切り替えボタン
  tab_inputs.forEach(tab_input => {
    tab_input.setAttribute('data-index', _headIndex++); //head側のindex付与
    tab_input.addEventListener('change', (ev) => {
      let myindex = ev.target.getAttribute('data-index');
      tabBody_items.forEach(tabBody_item => {
        if(tabBody_item.getAttribute('data-index') != myindex) {
          tabBody_item.classList.remove('__show-tabitem')
        }
        else {
          tabBody_item.classList.add('__show-tabitem')
        }
      });
    });
  });
}